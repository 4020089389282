// @flow
import EventEmitter from '@common/services/EventEmitter';
import ApiService from '@api/service';
import FileHelpers from '@helpers/FileHelpers';

import type { Error, Listener, QueryParams } from '@core/types';

import { API_PATHS } from '@app/constants/paths';

const { GROUP_CLASSES_EXPORT } = API_PATHS;

type OnChange = (listener: Listener) => Function;
type FetchExport = (params: QueryParams) => Promise<boolean | null>;
type UpdateValues = (newLoading: boolean, newErrors: Error[]) => void;
type Trigger = () => void;

class GroupClassExportService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.errors = [];
    this.loading = false;
  }

  eventEmitter: EventEmitter;
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (newLoading: boolean, newErrors: Error[]): void => {
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  FetchExport: FetchExport = (): Promise<boolean | null> => {
    this.updateValues(true, []);

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASSES_EXPORT,
    })
      .catch((error) => {
        if (error.response.data?.violations) {
          this.updateValues(false, error.response.data.violations);
        }
        return Promise.reject();
      })
      .then((payload) => {
        if (payload.data) {
          FileHelpers.saveFile(payload);
          this.updateValues(false, this.errors);
          return true;
        }
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: GroupClassExportService = new GroupClassExportService();
export default instance;
